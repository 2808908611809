import { getVendorFromItem } from "./productUtil"
import { BasketItemType, VendorType } from "social-supermarket-model"

export const groupBasketItemsByVendor = (
  items: BasketItemType[]
): [{ [key: string]: VendorType }, { [key: string]: BasketItemType[] }] => {
  const vendorMap = {}
  const groupedItems = {}
  items.forEach(item => {
    let vendor = getVendorFromItem(item)

    if (!groupedItems[vendor.slug]) {
      vendorMap[vendor.slug] = vendor
      groupedItems[vendor.slug] = []
    }
    groupedItems[vendor.slug].push(item)
  })
  return [vendorMap, groupedItems]
}
