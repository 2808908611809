import * as React from "react"
import { useContext, useState, useEffect } from "react"
import styled from "styled-components"
import { BasketContext } from "../../context/BasketProvider"
import { rouge } from "../../constants/colors"
import { PrimaryButton } from "social-supermarket-components"

const ErrorMessage = styled.p`
  font-size: 0.9em;
  color: ${rouge};
`

const Form = styled.form`
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;

  input {
    flex: 1;
  }

  button {
    max-width: 170px;
  }
`

interface Props {}

const CouponForm = ({}: Props) => {
  const { applyCoupon } = useContext(BasketContext)
  const [value, setValue] = useState<string>("")
  const [error, setError] = useState<string>("")

  const onSubmit = event => {
    event.preventDefault()
    applyCoupon(value, setError)
    setValue("")
  }

  useEffect(() => {
    setError("")
  }, [value])

  return (
    <>
      <Form onSubmit={onSubmit}>
        <input
          type="text"
          placeholder={"Coupon / Gift Code"}
          value={value}
          onChange={event => setValue(event.target.value)}
        />
        <PrimaryButton disabled={!value}>Apply</PrimaryButton>
      </Form>
      {error && <ErrorMessage>Unable to validate coupon</ErrorMessage>}
    </>
  )
}

export default CouponForm
