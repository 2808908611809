import * as React from "react"
import { useContext, useState } from "react"
import Layout from "../../../components/Layout"
import { BasketContext } from "../../context/BasketProvider"
import styled from "styled-components"
import Seo from "../../../components/Seo"
import { navigate } from "gatsby"
import Spinner from "../../components/Spinner"
import GroupedBasketContents from "./GroupedBasketItems"
import BasketTotals from "./BasketTotals"
import CouponForm from "./CouponForm"
import LinkButton from "../../components/button/LinkButton"
import ProceedToCheckoutForm from "./ProceedToCheckoutForm"
import VendorNoticeConfirmationModal from "./VendorNoticeConfirmationModal"
import { formatPrice, screenSizes } from "social-supermarket-model"

const Container = styled.div`
  display: flex;
  position: relative;
  min-height: 300px;
  padding: 20px 0;

  @media (max-width: ${screenSizes.largeDesktop}px) {
    padding: 20px 10px;
  }

  @media (max-width: ${screenSizes.desktop}px) {
    padding: 0 10px;
    flex-direction: column;
  }
`
const LeftContainer = styled.div`
  flex: 1;
  padding-top: 20px;
  margin-right: 10px;

  @media (max-width: ${screenSizes.desktop}px) {
    margin-right: 0;
  }
`
const RightContainer = styled.div`
  flex: 1;
  padding-top: 20px;
  margin-left: 10px;

  @media (max-width: ${screenSizes.desktop}px) {
    margin-left: 0;
  }
`
const Totals = styled.div`
  margin-bottom: 20px;
`
const RemoveCoupons = styled.div`
  text-align: right;
`
const GiftCards = styled.div``
const GiftCard = styled.div`
  display: flex;
  margin-bottom: 20px;

  font-size: 0.9em;

  > div {
    margin-right: 20px;
  }
  div {
    div {
      font-weight: bold;
    }
  }
`
const Coupons = styled.div``
const Coupon = styled.div`
  font-style: italic;
  border-radius: 5px;
  font-size: 0.9em;
  padding: 5px;
`

const BasketPage = () => {
  const [dateError, setDateError] = useState<string>("")
  const [showModal, setShowModal] = useState<boolean>(false)
  const {
    isLoading,
    removeCoupons,
    removeGiftCards,
    giftCards,
    basket: { coupons, items },
  } = useContext(BasketContext)

  const handleProceedToCheckout = () => {
    // const soonestShippingDate = getSoonestShippingDate([], 1)
    // const isInvalidDateSelected = items.some(
    //   ({ deliveryDate, product }) =>
    //     deliveryDate &&
    //     product.vendor.name === "Social Supermarket Hampers" &&
    //     soonestShippingDate.setHours(0, 0, 0, 0) > new Date(deliveryDate).setHours(0, 0, 0, 0)
    // )
    //
    // if (isInvalidDateSelected) {
    //   return setDateError(
    //     "Please select another date. Choose the delivery date from the date picker"
    //   )
    // }
    //
    // setShowModal(true)

    navigate("/checkout")
  }

  const handleConfirm = () => {
    navigate("/checkout")
  }

  const resetDateError = () => {
    setDateError("")
  }

  return (
    <Layout>
      <Seo title={"Your Basket"} description={"Your Basket"} />
      <Container>
        <Spinner isLoading={isLoading} />
        <LeftContainer>
          <GroupedBasketContents dateError={dateError} resetDateError={resetDateError} />
        </LeftContainer>
        <RightContainer>
          <Totals>
            <BasketTotals showCountdown />
          </Totals>
          <CouponForm />
          {coupons && coupons.length > 0 && (
            <Coupons>
              {coupons.map(coupon => (
                <Coupon>{coupon.code}</Coupon>
              ))}
              <RemoveCoupons>
                <LinkButton onClick={removeCoupons}>Remove Coupons</LinkButton>
              </RemoveCoupons>
            </Coupons>
          )}
          {giftCards && giftCards.length > 0 && (
            <GiftCards>
              {giftCards.map(({ code, balance, usage }) => (
                <GiftCard>
                  <div>
                    <div>Gift Card</div> {code}
                  </div>
                  <div>
                    <div>Balance</div> {formatPrice(balance)}
                  </div>
                  <div>
                    <div>Using</div> {formatPrice(usage)}
                  </div>
                </GiftCard>
              ))}
              <RemoveCoupons>
                <LinkButton onClick={removeGiftCards}>Remove Gift Cards</LinkButton>
              </RemoveCoupons>
            </GiftCards>
          )}

          <ProceedToCheckoutForm onProceedToCheckoutClick={handleProceedToCheckout} />
        </RightContainer>
      </Container>
      <VendorNoticeConfirmationModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={handleConfirm}
      />
    </Layout>
  )
}

export default BasketPage
