import * as React from "react"
import styled from "styled-components"
import { FC, useContext } from "react"
import Modal from "../../components/modal/Modal"
import { BasketContext } from "../../context/BasketProvider"
import VendorConfirmBasketItems from "./VendorConfirmBasketItems"
import { borderGrey } from "../../constants/colors"
import { tablet } from "../../constants/screenSizes"
import { PrimaryButton } from "social-supermarket-components"
import { groupBasketItemsByVendor } from "../../util/basketUtil"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
const ItemsContainer = styled.div``
const Body = styled.div`
  flex: 1;
  padding: 40px;
  overflow: auto;

  @media (max-width: ${tablet}px) {
    padding: 20px;
  }
`
const Notice = styled.div`
  margin-bottom: 20px;
`

const Footer = styled.div`
  width: 100%;
  border-top: 1px solid ${borderGrey};
  padding: 5px;
  display: flex;
  justify-content: flex-end;
`

interface Props {
  show: boolean
  onClose: () => void
  onConfirm: () => void
}

const VendorNoticeConfirmationModal: FC<Props> = ({ show, onClose, onConfirm }) => {
  const { basket, updateProduct } = useContext(BasketContext)
  const sortedItems = basket.items.sort((a, b) => {
    if (a.product.vendor?.deliveryNotice) {
      return -1
    }
  })
  const [vendorMap, groupedItems] = groupBasketItemsByVendor(sortedItems)
  return (
    <Modal show={show} onClose={onClose}>
      <Container>
        <Body>
          {Object.keys(groupedItems).length > 1 && (
            <Notice>
              <b>Please note:</b> Social Supermarket is a marketplace, and products will be
              dispatched separately from each Social Enterprise brand.
            </Notice>
          )}
          <ItemsContainer>
            {Object.entries(groupedItems).map(([vendorSlug, items]) => (
              <VendorConfirmBasketItems
                key={vendorSlug}
                items={items}
                vendor={vendorMap[vendorSlug]}
              />
            ))}
          </ItemsContainer>
        </Body>
        <Footer>
          <PrimaryButton onClick={onConfirm}>Proceed</PrimaryButton>
        </Footer>
      </Container>
    </Modal>
  )
}

export default VendorNoticeConfirmationModal
